// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ application_daisyui.js')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// Example: Load Rails libraries in Vite.
//
import * as Turbo from '@hotwired/turbo';
Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'


// -> shadcdn is the only css import we need when using vite
//import "./todo.tailwind.css";

//import '../stylesheets/application.css';

import './../../assets/stylesheets/components/alert.css';
import './../stylesheets/forms.css';
import './../stylesheets/stylesheets_daisyui.css';
import './../stylesheets/tailwind.css';


import { initTableSearch } from './../alpinejs/table-search';
window.initTableSearch = initTableSearch;

// alpinejs
import Alpine from 'alpinejs';
Alpine.start();


// toggle dark mode
const setupThemeToggle = () => {

    // save theme to local storage
    if (localStorage.getItem("theme") === null) {
        const cookieTheme = document.cookie
            .split("; ")
            .find((row) => row.startsWith("theme="))
            ?.split("=")[1];
        if (cookieTheme) {
            localStorage.setItem("theme", cookieTheme);
            document.documentElement.setAttribute("data-theme", cookieTheme);
        }
    }
    document.querySelector('.theme-controller').addEventListener('change', function(e) {
        const theme = e.target.checked ? 'dark' : 'light';
        document.documentElement.setAttribute('data-theme', theme);
        document.cookie = `theme=${theme}; path=/; max-age=31536000`; // 1 year expiry
    })
}

document.addEventListener("DOMContentLoaded", setupThemeToggle);
document.addEventListener("turbo:load", setupThemeToggle);

// auto hide toasts
const removeToasts = () => {
    setTimeout(() => {
        document.getElementById('flash')?.remove();
    }, 10000);
};

document.addEventListener("DOMContentLoaded", removeToasts);
document.addEventListener("turbo:load", removeToasts);
//