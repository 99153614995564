import { debounce } from './utils';

export function initTableSearch() {
  return {
    isLoading: false,
    searchText: '',
    init() {
      this.$watch('searchText', debounce((value) => {
        this.isLoading = true;
        const form = this.$el.querySelector('form');
        
        document.addEventListener('turbo:before-fetch-request', () => {
          this.isLoading = true;
        }, { once: true });
        
        document.querySelector('#search_results').addEventListener('turbo:frame-load', () => {
          this.isLoading = false;
        }, { once: true });

        form.requestSubmit();
      }, 300));
    },

  }
} 